<template>
	<div class="p-grid">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Mis Pagos')}}</h3>
		</div>

		<div class="p-col-12">
			<div class="card">
				<DataTable :value="creditos" :scrollable="true" scrollHeight="200px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5>{{$t('Créditos')}}</h5>
						</div>
					</template>
					<Column :header="$t('Estatus')" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="slotProps.data.pagada"/>
							<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
							<strong v-if="slotProps.data.pagada">{{$t('Pagado')}}</strong>
							<strong v-else>{{$t('Pendiente')}}</strong>
						</template>
					</Column>
                    <Column field="fecha" :header="$t('Fecha')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.fecha}}                        
                        </template>
                    </Column>
                    <Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Monto')" :sortable="true" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.monto<0" style="color:red;">
                                {{moneda(slotProps.data.monto)}}
                            </div>
                            <div v-else>
                                {{moneda(slotProps.data.monto)}}
                            </div>
                        </template>
                    </Column>
                    <Column :header="$t('Descripción')" headerStyle="width: 150px">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.pagada">
                                {{$t('Fecha')}}: {{convfech(slotProps.data.pag_fecha)}}<br>
                                {{$t('Referencia')}}: {{slotProps.data.pag_referencia}}<br>
                                {{$t('Monto')}}: {{moneda(slotProps.data.pag_monto)}}
                            </div>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>
        <div class="p-col-12">
			<div class="card">
				<DataTable :value="deudas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers"  class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5>{{$t('Pagos Pendientes')}}</h5>
						</div>
					</template>
                    <Column field="codigo" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
                        <template #body="slotProps">
                            {{slotProps.data.codigo}}                              
                        </template>
                    </Column>
                    <Column field="cliente" :header="$t('Cliente')+' #'" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.cliente}}                              
                        </template>
                    </Column>
                    <Column field="vencimiento" :header="$t('Vencimiento')" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.vencimiento}}                              
                        </template>
                    </Column>
                    <Column field="fecha" :header="$t('Fecha')" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.fecha}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Tipo')" field="name" >
                        <template #body="slotProps">
                            <div v-if="i18n.locale() == 'es'">
								{{slotProps.data.nombre}}
							</div>
                            <div v-if="i18n.locale() == 'en'">
								{{slotProps.data.name}}
							</div>								                             
                        </template>
                    </Column>                        
                    <Column :header="$t('Monto')">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.monto)}}                              
                        </template>
                    </Column>
					<template #footer>
                        <Button :label="$t('Reportar Pago')" style="width: 150px;" icon="bi bi-cash-stack" class="p-button-primary p-mr-2" @click="Pagar()"/>
					</template>
                </DataTable>
			</div>
        </div>
		<div class="p-col-12">
			<div class="card">
                <DataTable :value="facturas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
							<h5>{{$t('Facturas')}}</h5>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
                            </span>
                        </div>
                    </template>
					<Column field="id" header="Estatus" :sortable="true">
						<template #body="slotProps">
							<Avatar icon="bi bi-shield-check" class="p-mr-2" size="large" style="background-color:green; color: #ffffff" v-if="slotProps.data.pagada"/>
							<Avatar icon="bi bi-shield-exclamation" class="p-mr-2" size="large" style="background-color:yellow; color: #ffffff" v-else/>
							<strong v-if="!slotProps.data.pagada && i18n.locale() == 'es'">{{opciones[1].nombre}}</strong>
							<strong v-if="!slotProps.data.pagada && i18n.locale() == 'en'">{{opciones[1].name}}</strong>
							<strong v-if="slotProps.data.pagada && i18n.locale() == 'es'">{{opciones[2].nombre}}</strong>
							<strong v-if="slotProps.data.pagada && i18n.locale() == 'en'">{{opciones[2].name}}</strong>
						</template>
					</Column>
                    <Column field="fechad" :header="$t('Fecha')" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.fechad}}                              
                        </template>
                    </Column>
                    <Column field="id" :header="$t('Nº')" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.id}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Monto')">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.monto)}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Pagos')">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.recibido)}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Deudas')">
                        <template #body="slotProps">
                            {{moneda(slotProps.data.pendiente)}}                              
                        </template>
                    </Column>
                    <Column :header="$t('Ver')">
                        <template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data.firmado)" v-if="slotProps.data.pagada && slotProps.data.firmado"/>
                        </template>
                    </Column>
                </DataTable>
			</div>
        </div>


	</div>
	

</template>

<script>
import API from "../service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import url from "@/service/_URL";
import PDF from "../service/PDF";

export default {
	components: {
    },
	data() {
		return {
			insert: false,
			edit: false,
			del: false,
			i18n: null,
            caso: null,
            facturas: [],
            selectedCustomers: null,
			filters: {},
			url: null,
			bancos: [],
			tipos: [],
			deudas: [],
			creditos: [],
			opciones: [
				{code: 0, nombre: 'Todos', name: 'All'},
				{code: 1, nombre: 'Pendiente', name: 'Slope'},
				{code: 2, nombre: 'Aprobado', name: 'Approved'}
			],
		}
	},
	productService: null,
	eventService: null,
	created() {
		this.url = url;
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
			this.$store.commit('Loading');
			this.totaldeudas = 0;
            this.facturas = [];
            this.deudas = [];
            this.creditos = [];
            const Consulta = new API('Pagos');
            Consulta.Ini('MisPagos').then(result => {
                //this.$store.state.error = result.consult.creditos[0];	
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                if(result.consult.facturas){
                    this.facturas = result.consult.facturas;
                }
                if(result.consult.deudas){
                    this.deudas = result.consult.deudas;
                }
                if(result.consult.creditos){
                    this.creditos = result.consult.creditos;
                }
                this.bancos = result.consult.bancos;
                this.tipos = result.consult.tipos;
                this.$store.commit('Loading');
            }); 
        },
        VerPDF(data){
            window.open(url.api+'firmado/'+data, '_blank');
        },
		Pagar(){
			this.$router.push({ path: '/payment'});
		},
        Ver(data){
            const doc = new PDF();
            doc.Factura(data);
        },
        close() {
            this.caso = null;
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
		toggle1(event) {
			this.$refs.op1.toggle(event);
		},
		toggle2(event) {
			this.$refs.op2.toggle(event);
		},
		toggle3(event) {
			this.$refs.op3.toggle(event);
		},
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency:this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},
	},
	computed: {

    }

}
</script>
<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
